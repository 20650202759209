import axios from "axios";
import { baseUrl } from "../utils/constants";

export const getValuesByTraitApi = async (id: string) => {
  try {
    const res = await axios.get(`${baseUrl}/value/${id}/trait`);
    if (res.data.error) {
      throw res.data.error;
    } else {
      return res.data.values;
    }
  } catch (err) {
    throw err;
  }
};

export const createValueApi = async (
  id: string,
  name?: string,
  point?: string
) => {
  try {
    const res = await axios.post(`${baseUrl}/value/${id}`, {
      name: name,
      point: point,
    });
    if (res.data.error) {
      throw res.data.error;
    } else {
      return {
        name: res.data.value.name,
        id: res.data.value._id,
        point: res.data.value.point,
      };
    }
  } catch (err) {
    throw err;
  }
};
export const deleteValueApi = async (id: string) => {
  try {
    const res = await axios.delete(`${baseUrl}/value/${id}`);
    if (res.data.error) {
      throw res.data.error;
    } else {
      return res.data.value;
    }
  } catch (err) {
    throw err;
  }
};

export const updateValueApi = async (
  id: string,
  name?: string,
  point?: string
) => {
  const body = {};
  try {
    const res = await axios.put(`${baseUrl}/value/${id}`, {
      name: name,
      point: point,
    });

    if (res.data.error) {
      throw res.data.error;
    } else {
      return {
        id: res.data.value._id,
        name: res.data.value.name,
        point: res.data.value.point,
      };
    }
  } catch (err) {
    throw err;
  }
};
