import { Box } from "@mui/material";
import { useTimer } from "react-timer-hook";
import { gradient } from "../utils/styles";
import FlexBox from "./containers/FlexBox";
import Body1 from "./typography/Body1";

function Timer({ expiryTimestamp }: { expiryTimestamp: Date }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  return (
    <Box style={{ textAlign: "center" }}>
      <FlexBox
        sx={{
          px: 3,
          py: 4,
          border: 1,
          borderRadius: 2,
          borderColor: "primary.main",
          my: 2,
          color: "white",
        }}
      >
        <Body1 text={`${days} Days :`} />
        <Body1 text={`${hours} Hours :`} />
        <Body1 text={`${minutes} Minutes :`} />
        <Body1 text={`${seconds} Seconds`} />
      </FlexBox>
    </Box>
  );
}

export default function App({ expiry }: { expiry: Date }) {
  return <div>{expiry && <Timer expiryTimestamp={expiry} />}</div>;
}
