import { useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import { isAdmin } from "../api/adminApi";
import Section from "../components/containers/Section";
import { Box } from "@mui/system";
import FlexBox from "../components/containers/FlexBox";
import PlayerTable from "../components/PlayersTable";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Background from "../components/containers/Background";

const PlayersPage = () => {
  const { publicKey } = useWallet();
  const navigate = useNavigate();
  const { userAddress, tournamentId } = useParams();

  useEffect(() => {
    if (!publicKey) {
      navigate("/");
    } else {
      isAdmin(publicKey.toString()).then((admin) => {
        if (!admin) {
          navigate("/");
        }
      });
    }
  }, [publicKey]);
  return (
    <Background>
      <Section>
        <FlexBox dir="column">
          <Header />
        </FlexBox>
        {userAddress && tournamentId && (
          <PlayerTable userAddress={userAddress} tournamentId={tournamentId} />
        )}
      </Section>
    </Background>
  );
};

export default PlayersPage;
