import { SxProps, Typography } from "@mui/material";

const style = {
  fontSize: 18,
  fontWeight: "400",
  color: "white",
  fontFamily: "inherit",
};

const Body1 = ({ text, sx }: { text: string; sx?: SxProps }) => {
  return (
    <Typography sx={{ ...style, ...sx }} variant="body1">
      {text}
    </Typography>
  );
};

export default Body1;
