import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import FlexBox from "../components/containers/FlexBox";
import Section from "../components/containers/Section";
import Logo from "../components/Logo";
import { useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import DisconnectWalletButton from "../components/buttons/DisconnectWalletButton";
import PrimaryButton from "../components/buttons/PrimaryButton";
import { isAdmin } from "../api/adminApi";
import { useDispatch } from "react-redux";
import TournamentTable from "../components/TournamentTable";
import { createTournamentApi } from "../api/tournamentApi";
import { addTournament } from "../redux/tournamentSlice";
import LoadingComponent from "../components/LoadingBackdrop";
import MaterialSnackbar from "../components/snackbars/ErrorSnackbar";
import Background from "../components/containers/Background";

const AdminDashboardPage: FC = () => {
  const navigate = useNavigate();
  const { publicKey } = useWallet();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<unknown>();
  const dispatch = useDispatch();

  const createTournament = async (days: string) => {
    try {
      setLoading(true);
      const res = await createTournamentApi(days);
      dispatch(addTournament(res));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(true);
      setMessage(err);
      setTimeout(() => {
        setError(false);
      }, 4000);
    }
  };

  useEffect(() => {
    if (!publicKey) {
      navigate("/");
    } else {
      isAdmin(publicKey.toString()).then((admin) => {
        if (!admin) {
          navigate("/");
        }
      });
    }
  }, [publicKey]);
  return (
    <Background>
      <LoadingComponent open={loading} />
      <MaterialSnackbar open={error} setOpen={setError} message={message} />
      <Section>
        <FlexBox dir="column">
          <FlexBox
            jc="space-between"
            sx={{
              width: "100%",
              mb: 8,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Logo />
            <DisconnectWalletButton />
          </FlexBox>
          <FlexBox
            sx={{
              flexDirection: {
                xs: "column",
                md: "row",
              },
            }}
          >
            <PrimaryButton
              text="Trait dashboard"
              sx={{ mt: 3, mr: { md: 4 } }}
              onClick={() => {
                navigate("/admin/trait");
              }}
            />

            <PrimaryButton
              text="3 day tournament"
              sx={{ mt: 3, mr: { md: 4 } }}
              onClick={() => {
                createTournament("3");
              }}
            />
            <PrimaryButton
              text="5 day tournament"
              sx={{ mt: 3, mr: { md: 4 } }}
              onClick={() => {
                createTournament("5");
              }}
            />

            <PrimaryButton
              text="Preference"
              sx={{ mt: 3 }}
              onClick={() => {
                navigate("/admin/config")
              }}
            />
          </FlexBox>

          <TournamentTable />
        </FlexBox>
      </Section>
    </Background>
  );
};

export default AdminDashboardPage;
