import React, { useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import { isAdmin } from "../api/adminApi";
import Section from "../components/containers/Section";
import { Box } from "@mui/system";
import FlexBox from "../components/containers/FlexBox";
import Logo from "../components/Logo";
import DisconnectWalletButton from "../components/buttons/DisconnectWalletButton";
import { useParams } from "react-router-dom";
import UserTable from "../components/UserTable";
import { getTournamentByIdApi } from "../api/tournamentApi";
import { Typography } from "@mui/material";
import Background from "../components/containers/Background";
import Heading2 from "../components/typography/Heading2";
import Body2 from "../components/typography/Body2";
import Heading1 from "../components/typography/Heading1";

interface UserParams {
  id: string;
}

const UserPage = () => {
  const { publicKey } = useWallet();
  const { id } = useParams();
  const [tournament, setTournament] = useState<any>({});
  const navigate = useNavigate();

  const getTournament = async (id: string) => {
    try {
      const res = await getTournamentByIdApi(id);
      setTournament(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!publicKey) {
      navigate("/");
    } else {
      isAdmin(publicKey.toString()).then((admin) => {
        if (!admin) {
          navigate("/");
        } else {
          if (id) {
            getTournament(id);
          }
        }
      });
    }
  }, [publicKey]);
  return (
    <Background>
      <Section>
        <FlexBox dir="column">
          <FlexBox
            jc="space-between"
            sx={{
              width: "100%",
              mb: 4,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Logo />
            <DisconnectWalletButton />
          </FlexBox>
        </FlexBox>
       {/*  {tournament.winner && (
          <FlexBox sx={{ mt: 4, flexDirection: { xs: "column", md: "row" } }}>
            <Heading2 text="Winner" sx={{ mr: 4 }} />
            <Body2 text={tournament.winner.address} />
          </FlexBox>
        )} */}

        {id && <UserTable tournamentId={id} />}
      </Section>
    </Background>
  );
};

export default UserPage;
