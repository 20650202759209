import { useState, useEffect } from "react";
import { getNextScheduledTournamentApi } from "../api/tournamentApi";

const useScheduledTournament = () => {
  const [scheduledTournament, setScheduledTournament] = useState<any>();
  const [loadingScheduledTournament, setLoadingScheduledTournament] =
    useState<boolean>(false);

  const getNextScheduledTournament = async () => {
    try {
      setLoadingScheduledTournament(true);
      const res = await getNextScheduledTournamentApi();
      setLoadingScheduledTournament(false);
      setScheduledTournament(res);
    } catch (err: any) {
      setLoadingScheduledTournament(false);
      if (err.code === 404) {
        setScheduledTournament(null);
      }
    }
  };

  useEffect(() => {
    try {
      getNextScheduledTournament();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return [scheduledTournament, loadingScheduledTournament];
};

export default useScheduledTournament;
