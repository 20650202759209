import { Box, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import DisconnectWalletButton from "../components/buttons/DisconnectWalletButton";
import FlexBox from "../components/containers/FlexBox";
import Section from "../components/containers/Section";
import Logo from "../components/Logo";
import { useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import ResultTable from "../components/ResultTable";
import { getLastResultApi } from "../api/resultApi";
import moment from "moment";
import Background from "../components/containers/Background";
import Heading2 from "../components/typography/Heading2";
import Body2 from "../components/typography/Body2";
import LoadingComponent from "../components/LoadingBackdrop";

interface Row {
  id: string;
  user: string;
  players: string;
  score: string;
}

const HomePage: FC = () => {
  const navigate = useNavigate();
  const { publicKey } = useWallet();
  const [rows, setRows] = useState<Row[]>([]);
  const [result, setResult] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const loadResult = async () => {
    try {
      if (publicKey) {
        setLoading(true);
        const user = publicKey.toString();
        const res = await getLastResultApi(user);
        setLoading(false);
        console.log(null)
        if (res == null) {
          setResult(undefined);          
        } else {
          setResult(res);
          const rows = res.results.map((item: any) => {
            const score = item.score.toFixed(3);
            return {
              id: item._id,
              user: item.user.address,
              players: item.players,
              score: score,
            };
          });
          setRows(rows);
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    if (!publicKey) {
      navigate("/");
    } else {
      loadResult();
    }
    return () => {
      setResult(undefined);
    };
  }, [publicKey]);
  return (
    <Background>
      <LoadingComponent open={loading} />
      <Section>
        <FlexBox dir="column">
          <FlexBox
            jc="space-between"
            sx={{
              width: "100%",
              mb: 8,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Logo />
            <DisconnectWalletButton />
          </FlexBox>
          {result && !loading ? (
            <Box sx={{ width: "100%", mt: 4 }}>
              <FlexBox jc="space-around" sx={{ width: "100%" }}>
                <FlexBox dir="column">
                  <Heading2 text="Total Players" sx={{ textAlign: "center" }} />
                  <Body2 text={result.tournament.players.length} />
                </FlexBox>
                <FlexBox jc="center" dir="column">
                  <Heading2
                    text="Tournament Started"
                    sx={{ textAlign: "center" }}
                  />
                  <Body2
                    text={moment(result.tournament.startedAt).format(
                      "DD/MM/YY hh:mm"
                    )}
                  />
                </FlexBox>
                <FlexBox jc="center" dir="column">
                  <Heading2
                    text="Tournament Ended"
                    sx={{ textAlign: "center" }}
                  />
                  <Body2
                    text={moment(result.tournament.expiresIn).format(
                      "DD/MM/YY hh:mm"
                    )}
                  />
                </FlexBox>
              </FlexBox>

              {rows && <ResultTable rows={rows} />}
              <FlexBox
                jc="space-between"
                sx={{ flexDirection: { xs: "column", md: "row" } }}
              >
                <FlexBox sx={{ mb: 1 }} dir="column">
                  <Heading2 text="Winner" />
                  <Body2 text={result.winner.user.address} />
                </FlexBox>
                <FlexBox dir="column" sx={{ mt: { xs: 2, md: 0 } }}>
                  <Heading2 text="Score" />
                  <Body2 text={`${result.winner.score.toFixed(2)} %`} />
                </FlexBox>
              </FlexBox>
            </Box>
          ) : (
            <Heading2 text="No previous results" />
          )}
        </FlexBox>
      </Section>
    </Background>
  );
};

export default HomePage;
