import { Button, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import FlexBox from "./containers/FlexBox";
import EditIcon from "@mui/icons-material/Edit";
import EditTraitBackdrop from "./backdrops/EditTraitBackdrop";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Trait from "../entities/Trait";
import { removeTrait, setTraits } from "../redux/traitSlice";
import { deleteTraitApi, getTraitsApi } from "../api/traitApi";
import { useNavigate } from "react-router-dom";
import { gradient } from "../utils/styles";

const TraitTable = () => {
  const [editing, setEditing] = useState<boolean>(false);
  const [traitId, setTraitId] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const traits: Trait[] = useSelector((state: RootState) => state.traits);

  const loadTraits = async () => {
    try {
      const res = await getTraitsApi();
      const traits: Trait[] = res.map((trait: any) => {
        return { id: trait._id, name: trait.name };
      });
      dispatch(setTraits(traits));
    } catch (err) {
      console.log(err);
    }
  };

  const deleteTrait = async (id: string) => {
    try {
      const res = await deleteTraitApi(id);
      dispatch(removeTrait(res._id));
    } catch (err) {
      console.log(err);
    }
  };

  // columns for table
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      hide: true,
      sortable: false,
      width: 420,
    },

    {
      field: "name",
      headerAlign: "center",
      align: "center",
      headerName: "Trait",
      sortable: false,
      width: 200,
    },
    {
      field: "value",
      headerAlign: "center",
      align: "center",
      headerName: "Values",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            sx={{ color: "primary.light" }}
            onClick={() => {
              navigate(`/admin/values/${cellValues.id.toString()}`);
            }}
          >
            Get
          </Button>
        );
      },
      width: 150,
    },
    {
      field: "edit",
      headerName: "Edit",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <IconButton
            onClick={() => {
              setTraitId(cellValues.id.toString());
              setEditing(true);
            }}
          >
            <EditIcon sx={{ color: "white" }} />
          </IconButton>
        );
      },
      width: 150,
    },
    {
      field: "delete",
      headerAlign: "center",
      align: "center",
      headerName: "Delete",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <IconButton
            aria-label="delete"
            color="error"
            onClick={() => {
              deleteTrait(cellValues.id.toString());
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
      width: 150,
    },
  ];

  useEffect(() => {
    loadTraits();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        my: 1,
        "& p": { color: "white" },
        "& .MuiTablePagination-actions": {
          "& button": { color: "white" },
        },
      }}
    >
      <EditTraitBackdrop open={editing} setOpen={setEditing} id={traitId} />
      <FlexBox dir="column">
        <Box sx={{ width: "100%", my: 5 }}>
          <DataGrid
            sx={{
              px: 2,
              borderImage: gradient,
              borderImageWidth: 1,
              borderImageSlice: 1,
              color: "white",
              "& .MuiDataGrid-columnHeaderTitle": { fontSize: 16 },
            }}
            rows={traits}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </Box>
      </FlexBox>
    </Box>
  );
};

export default TraitTable;
