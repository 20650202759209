import { useEffect, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import FlexBox from "../components/containers/FlexBox";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  deleteTournamentApi,
  getAllTournamentsApi,
} from "../api/tournamentApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { removeTournament, setTournaments } from "../redux/tournamentSlice";
import { useNavigate } from "react-router-dom";
import { gradient } from "../utils/styles";
import axios from "axios";

const TournamentTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentTournamentId, setCurrentTournamentId] = useState<
    string | undefined
  >();
  const tournaments = useSelector((state: RootState) => state.tournaments);

  const deleteTournament = async (id: string) => {
    try {
      const res = await deleteTournamentApi(id);

      dispatch(removeTournament(res.tournament._id));
    } catch (err) {
      console.log("error caught", err);
    }
  };
  const loadTournaments = async () => {
    try {
      const res = await Promise.all(await getAllTournamentsApi());
      console.log("tournamets from server", res);
      const rti = res.filter((tournament) => tournament.status === "Running");
      if (rti.length !== 0) {
        setCurrentTournamentId(rti[0].id);
      }

      dispatch(setTournaments(res));
    } catch (err) {
      console.log("error caught", err);
    }
  };

  // columns for table
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 250,
    },
    {
      field: "days",
      headerAlign: "center",
      align: "center",
      headerName: "Days",
      sortable: false,
      width: 70,
    },
    {
      field: "status",
      headerAlign: "center",
      align: "center",
      headerName: "Status",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <Typography
            sx={{ color: cellValues.value == "Running" ? "green" : "red" }}
          >
            {cellValues.value}
          </Typography>
        );
      },
      width: 90,
    },
    {
      field: "created",
      headerAlign: "center",
      align: "center",
      headerName: "Created",
      sortable: false,
      width: 150,
    },
    {
      field: "started",
      headerAlign: "center",
      align: "center",
      headerName: "Started",
      sortable: false,
      width: 150,
    },
    {
      field: "endDate",
      headerAlign: "center",
      align: "center",
      headerName: "Ends at",
      sortable: false,
      width: 150,
    },

    // {
    //   field: "winner",
    //   headerAlign: "center",
    //   align: "center",
    //   headerName: "Winner",
    //   sortable: false,
    //   width: 220,
    // },
    {
      field: "delete",
      headerAlign: "center",
      align: "center",
      headerName: "Delete",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <IconButton
            aria-label="delete"
            disabled={cellValues.id === currentTournamentId}
            color="error"
            onClick={() => {
              deleteTournament(cellValues.id.toString());
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
      width: 80,
    },
    {
      field: "address",
      headerAlign: "center",
      align: "center",
      headerName: "Address",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            sx={{ color: "white", borderColor: "primary.light" }}
            variant="outlined"
            onClick={() => {
              navigate(`/admin/users/${cellValues.id}`);
            }}
          >
            Get
          </Button>
        );
      },
      width: 100,
    },
    {
      field: "end",
      headerAlign: "center",
      align: "center",
      headerName: "End",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            sx={{ color: "white", borderColor: "primary.light" }}
            variant="outlined"
            disabled={cellValues.id !== currentTournamentId}
            onClick={() => {
              axios.get("http://localhost:4000/api/tournament/endTournament");
              navigate("/");
            }}
          >
            End
          </Button>
        );
      },
      width: 100,
    },
  ];

  useEffect(() => {
    loadTournaments();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        my: 3,
        "& .MuiTablePagination-displayedRows": {
          color: "white",
        },
      }}
    >
      <FlexBox dir="column">
        <Box
          sx={{
            width: "100%",
            my: 5,
            "& .MuiTablePagination-actions": {
              "& button": { "& svg": { color: "white" } },
            },
          }}
        >
          <DataGrid
            sx={{
              borderImage: gradient,
              borderImageWidth: 1,
              borderImageSlice: 1,
              px: 2,
              color: "white",
              "& .MuiDataGrid-columnHeaderTitle": { fontSize: 16 },
            }}
            rows={tournaments}
            columns={columns}
            pageSize={10}
            autoHeight
            disableSelectionOnClick
          />
        </Box>
      </FlexBox>
    </Box>
  );
};

export default TournamentTable;
