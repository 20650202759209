import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { NFT } from "../entities/Nft";
import { RootState } from "../redux/store";

const useTotalStakedPoints = () => {
  const stakeNfts = useSelector((state: RootState) => state.nfts.stake);
  const [totalStakedPoints, setTotalStakedPoints] = useState<number>();

  useEffect(() => {
    const totalPointsArray = stakeNfts.map((nft: NFT) => {
      return nft.points;
    });
    console.log("staked points array", totalPointsArray);
    let totalPoints = 0;
    for (let i = 0; i < totalPointsArray.length; i++) {
      totalPoints = totalPointsArray[i] + totalPoints;
    }
    totalPoints = parseFloat(totalPoints.toFixed(1));

    setTotalStakedPoints(totalPoints);
  }, [stakeNfts]);

  return [totalStakedPoints];
};

export default useTotalStakedPoints;
