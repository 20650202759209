import axios from "axios";
import { baseUrl } from "../utils/constants";

export const getHallOfFameApi = async () => {
  try {
    const res = await axios.get(`${baseUrl}/user/hall_of_fame`);
    if (res.data.error) {
      throw {
        message: res.data.error,
      };
    } else {
      return res.data.hallOfFame;
    }
  } catch (err: any) {
    throw err.message;
  }
};
