import { Box } from "@mui/material";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
const ConnectWalletButton = () => {
  return (
    <Box
      sx={{
        "& button": {
          backgroundColor: "primary.main",
          minWidth: "15rem",
          display: "flex",
          justifyContent: "center",
        },
        "& .wallet-adapter-button:hover": {
          backgroundColor: "primary.dark",
        },
      }}
    >
      <WalletMultiButton />
    </Box>
  );
};

export default ConnectWalletButton;
