import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {
  getStakedPlayersApi,
  getStakedPlayersByUserApi,
} from "../api/playerApi";
import FlexBox from "./containers/FlexBox";
import Link from "@mui/material/Link";
import axios from "axios";
import { gradient } from "../utils/styles";

interface Row {
  id: string;
  uri: string;
  image: string;
}

const PlayerTable = ({
  userAddress,
  tournamentId,
}: {
  userAddress: string;
  tournamentId: string;
}) => {
  const [rows, setRows] = useState<Row[]>([]);

  const loadPlayers = async () => {
    try {
      const res = await getStakedPlayersByUserApi(userAddress, tournamentId);
      const rows: Row[] = await Promise.all(
        res.map(async (player: any) => {
          const res = await axios.get(player.uri);
          return {
            id: player.mint,
            uri: player.uri,
            image: res.data.image,
          };
        })
      );
      setRows(rows);
    } catch (err) {
      console.log(err);
    }
  };

  // columns for table
  const columns: GridColDef[] = [
    {
      field: "id",
      headerAlign: "center",
      align: "center",
      headerName: "Mints",
      hide: true,
      sortable: false,
      width: 420,
    },

    {
      field: "image",
      headerName: "Player",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <Box>
            <img src={cellValues.value} height="100px" />
          </Box>
        );
      },
      width: 300,
    },
    {
      field: "uri",
      headerAlign: "center",
      align: "center",
      headerName: "Metadata",
      sortable: false,
      renderCell: (cellValues) => {
        return <Link href={cellValues.value}>Get metadata</Link>;
      },
      width: 150,
    },
  ];

  useEffect(() => {
    loadPlayers();
  }, []);

  return (
    <Box sx={{ width: "100%", my: 3 }}>
      <FlexBox dir="column">
        <Box sx={{ width: "100%", my: 5 }}>
          <DataGrid
            sx={{
              borderImage: gradient,
              borderImageWidth: 1,
              borderImageSlice: 1,
              px: 2,
              color: "white",
              "& .MuiDataGrid-columnHeaderTitle": { fontSize: 16 },
            }}
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            rowHeight={120}
          />
        </Box>
      </FlexBox>
    </Box>
  );
};

export default PlayerTable;
