import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DisconnectWalletButton from "../components/buttons/DisconnectWalletButton";
import PrimaryButton from "../components/buttons/PrimaryButton";
import FlexBox from "../components/containers/FlexBox";
import Section from "../components/containers/Section";
import Logo from "../components/Logo";
import Body2 from "../components/typography/Body1";
import { useWallet } from "@solana/wallet-adapter-react";
import Timer from "../components/Timer";
import useTimer from "../hooks/useTimer";
import { getHallOfFameApi } from "../api/userApi";
import useRunningTournament from "../hooks/useRunningTournament";
import Background from "../components/containers/Background";
import HallOfFameTable from "../components/HallOfFameTable";
import CircularBar from "../components/CircularBar";

const HomePage: FC = () => {
  const navigate = useNavigate();
  const { publicKey } = useWallet();
  const [expiry] = useTimer();
  const [data, setData] = useState<any>();
  const [runningTournament] = useRunningTournament();
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const getHallOfFame = async () => {
    try {
      setLoadingData(true);
      const res = await getHallOfFameApi();
      setLoadingData(false);
      setData(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!publicKey) {
      navigate("/");
    } else {
      getHallOfFame();
    }
  }, [publicKey]);

  return (
    <Background>
      <Section>
        <FlexBox dir="column">
          <FlexBox
            jc="space-between"
            sx={{
              width: "100%",
              mb: 8,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Logo />
            <DisconnectWalletButton />
          </FlexBox>
          <FlexBox
            sx={{
              mb: 2,
              mt: 2,
              flexDirection: {
                xs: "column",
                md: "row",
              },
            }}
          >
            <PrimaryButton
              size="medium"
              sx={{ mr: { md: 4 }, mb: { xs: 4, md: 0 } }}
              text="Last Results"
              onClick={() => {
                navigate("/results");
              }}
            />
            <PrimaryButton
              text="Players on tournament"
              size="medium"
              onClick={() => {
                navigate("/players_on_tournament");
              }}
            />
          </FlexBox>
          <FlexBox sx={{ width: "100%" }}>
            {data && <HallOfFameTable data={data} />}
          </FlexBox>
          {loadingData && (
            <FlexBox sx={{ height: "50vh" }}>
              <CircularBar />
            </FlexBox>
          )}
          {runningTournament && (
            <FlexBox sx={{ mt: 2 }} dir="column">
              <Body2
                sx={{ mr: 2, color: "white" }}
                text="Next match starts in"
              />
              {expiry && <Timer expiry={expiry} />}
            </FlexBox>
          )}

          <PrimaryButton
            sx={{ mt: 2 }}
            text="Join tournament"
            size="medium"
            onClick={() => {
              navigate("/join");
            }}
          />
        </FlexBox>
      </Section>
    </Background>
  );
};

export default HomePage;
