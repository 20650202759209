import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Wallet } from "./components/Wallet";
import AdminDashboardPage from "./pages/AdminDashboardPage";
import HallOfFamePage from "./pages/HallOfFamePage";
import HomePage from "./pages/HomePage";
import JoinTournamentPage from "./pages/JoinTournamentPage";
import PlayersOnTournamentPage from "./pages/PlayersOnTournamentPage";
import PlayersPage from "./pages/PlayersPage";
import ResultsPage from "./pages/ResultsPage";
import TraitDashboardPage from "./pages/TraitDashboardPage";
import UserPage from "./pages/UserPage";
import ValuesPage from "./pages/ValuesPage";
import ConfigPage from "./pages/ConfigPage";
import "./App.css";

function App() {
  return (
    <Wallet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/join" element={<JoinTournamentPage />} />
          <Route path="/hall_of_fame" element={<HallOfFamePage />} />
          <Route
            path="/players_on_tournament"
            element={<PlayersOnTournamentPage />}
          />
          <Route path="/results" element={<ResultsPage />} />
          <Route path="/admin" element={<AdminDashboardPage />} />
          <Route path="/admin/users/:id" element={<UserPage />} />
          <Route
            path="/admin/players/:userAddress/:tournamentId"
            element={<PlayersPage />}
          />
          <Route path="/admin/trait" element={<TraitDashboardPage />} />
          <Route path="/admin/config" element={<ConfigPage />} />
          <Route path="/admin/values/:id" element={<ValuesPage />} />
        </Routes>
      </BrowserRouter>
    </Wallet>
  );
}

export default App;
