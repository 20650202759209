import { Box, Typography } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";

interface Props {
  mint: string;
  imageUrl: string;
  index: number;
  points: number;
}

const Nft = ({ mint, imageUrl, index, points }: Props) => {
  return (
    <Draggable draggableId={mint} index={index} key={mint}>
      {(provided) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            m: 4,
          }}
          dir="column"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <img src={imageUrl} width="110px" />

          <Typography
            sx={{ color: "white", mt: 1 }}
          >{`${points} Points`}</Typography>
        </Box>
      )}
    </Draggable>
  );
};

export default Nft;
