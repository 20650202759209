import { Button, IconButton, Paper, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FlexBox from "./containers/FlexBox";
import { useState } from "react";
import { updateTraitApi } from "../api/traitApi";
import { useDispatch } from "react-redux";
import { updateTraitState } from "../redux/traitSlice";

const EditTrait = ({ setOpen, id }: { setOpen: Function; id: string }) => {
  const [name, setName] = useState<string>("");
  const dispatch = useDispatch();
  const updateTrait = async () => {
    try {
      const res = await updateTraitApi(name, id);
      dispatch(updateTraitState(res));
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Paper
      elevation={4}
      sx={{
        px: 2,
        pt: 8,
        pb: 6,
        minWidth: "25rem",
        position: "relative",
        backgroundColor: "#1F1F1F",
      }}
    >
      <IconButton
        sx={{ position: "absolute", right: "2rem" }}
        color="primary"
        onClick={() => {
          setOpen(false);
        }}
      >
        <CloseIcon sx={{ color: "white" }} />
      </IconButton>
      <FlexBox dir="column">
        <TextField
          label="name"
          sx={{
            mt: 1,
            mb: 3,
            border: "none",
            "& input": { color: "#D6DBDF" },
            "& .MuiInputLabel-root": { color: "#D6DBDF" },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.main",
            },
          }}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{ my: 2, py: 3, px: 8 }}
          onClick={() => {
            updateTrait();
          }}
        >
          Update
        </Button>
      </FlexBox>
    </Paper>
  );
};

export default EditTrait;
