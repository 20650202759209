import { useState, useEffect } from "react";
import { getRunningTournamentApi } from "../api/tournamentApi";

const useTimer = () => {
  const [expiry, setExpiry] = useState<Date | undefined>(undefined);

  const getRunningTournament = async () => {
    const res = await getRunningTournamentApi();
    const dateToSet = new Date(res.expiresIn);
    setExpiry(dateToSet);
  };

  useEffect(() => {
    try {
      getRunningTournament();
    } catch (err) {}
  }, []);

  return [expiry];
};

export default useTimer;
