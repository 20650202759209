import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NFT } from "../entities/Nft";

interface State {
  total: NFT[] | undefined;
  free: NFT[];
  stake: NFT[];
  totalPoints: number;
}

const initialState: State = {
  free: [],
  stake: [],
  total: undefined,
  totalPoints: 0,
};

export const nftSlice = createSlice({
  name: "tournament",
  initialState,
  reducers: {
    setFreeNfts: (state: any, action: PayloadAction<NFT[]>) => {
      state.free = action.payload;
    },
    setStakeNfts: (state: any, action: PayloadAction<NFT[]>) => {
      state.stake = action.payload;
    },
    setTotalNfts: (state: any, action: PayloadAction<NFT[]>) => {
      state.total = action.payload;
    },
    setTotalPoints: (state: any, action: PayloadAction<number>) => {
      state.totalPoints = action.payload;
    },
    resetStakedNfts: (state: any) => {
      state.stake = [];
    },
    resetFreeNfts: (state: any) => {
      state.free = [];
    },
    removeFromFree: (state: any, action: PayloadAction<NFT>) => {
      state.free = state.free.filter(
        (item: any) => item.mint !== action.payload.mint
      );
    },
    addToFree: (state: any, action: PayloadAction<NFT>) => {
      state.free = [...state.free, action.payload];
    },
    addToStake: (state: any, action: PayloadAction<NFT>) => {
      // const toStake: NFT = state.total.filter(
      //   (item: any) => item.mint === action.payload
      // );
      // state.stake = [...state.stake, toStake];
      state.stake = [...state.stake, action.payload];
    },
    removeFromStake: (state: any, action: PayloadAction<NFT>) => {
      state.stake = state.stake.filter(
        (item: any) => item.mint !== action.payload.mint
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFreeNfts,
  removeFromFree,
  addToFree,
  addToStake,
  removeFromStake,
  resetStakedNfts,
  setStakeNfts,
  resetFreeNfts,
  setTotalNfts,
  setTotalPoints,
} = nftSlice.actions;

export default nftSlice.reducer;
