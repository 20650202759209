import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import FlexBox from "./containers/FlexBox";
import { Typography } from "@mui/material";
import { gradient } from "../utils/styles";

interface Row {
  id: string;
  user: string;
  players: string;
  score: string;
}

const ResultTable = ({ rows }: { rows: any }) => {
  // columns for table
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Mints",
      headerAlign: "center",
      align: "center",
      hide: true,
      sortable: false,
      width: 420,
    },

    {
      field: "user",
      headerAlign: "center",
      headerName: "Address",
      align: "center",
      sortable: false,
      width: 420,
    },
    {
      field: "players",
      headerAlign: "center",
      headerName: "Players",
      align: "center",
      sortable: false,
      width: 150,
    },
    {
      field: "score",
      headerName: "Score",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cellValues) => {
        return <Typography>{cellValues.value} %</Typography>;
      },
      width: 180,
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        my: 3,
      }}
    >
      <FlexBox dir="column">
        <Box
          sx={{
            width: "100%",
            my: 5,
            "& p": { color: "white" },
            "& .MuiTablePagination-actions": {
              "& button": { color: "white" },
            },
          }}
        >
          <DataGrid
            sx={{
              px: 2,
              color: "white",
              borderImage: gradient,
              borderImageWidth: 1,
              borderImageSlice: 1,
            }}
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            rowHeight={70}
          />
        </Box>
      </FlexBox>
    </Box>
  );
};

export default ResultTable;
