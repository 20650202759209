import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#581197",
      dark: "#581197",
    },
    white: "#ffffff",
  },
  //typography: {
  //   h1: {
  //     fontFamily: "Roboto ",
  //     fontSize: { xs: 12, lg: 52 },
  //     fontWeight: 400,
  //     color: "black.one",
  //   },
  //   h2: {
  //     fontSize: 40,
  //     fontWeight: 400,
  //     color: "black.one",
  //   },
  //   h3: {
  //     fontSize: 22,
  //     fontWeight: 500,
  //     color: "black.one",
  //   },
  //   h4: {
  //     fontSize: 18,
  //     fontWeight: 500,
  //     color: "black.one",
  //   },

  //   body1: {
  //     fontSize: 18,
  //     color: "black.two",
  //   },
  //   body2: {
  //     fontSize: 16,
  //     color: "black.two",
  //   },
  //   body3: {
  //     fontSize: 14,
  //     color: "black.two",
  //   },
  // },
  spacing: 4,
});

export default theme;
