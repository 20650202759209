import axios from "axios";
import Tournament from "../entities/Tournament";
import moment from "moment";
import { baseUrl } from "../utils/constants";

export const createTournamentApi = async (days: string) => {
  try {
    const res = await axios.post(`${baseUrl}/tournament`, {
      days,
    });
    if (res.data.error) {
      throw {
        message: res.data.error,
      };
    }
    const createdAt = moment(res.data.tournament.createdAt).format(
      "DD-MM-YYYY hh:mm"
    );
    const expiresIn = res.data.tournament.expiresIn
      ? moment(res.data.tournament.expiresIn).format("DD-MM-YYYY hh:mm")
      : "";
    const started = res.data.tournament.startedAt
      ? moment(res.data.tournament.startedAt).format("DD-MM-YYYY hh:mm")
      : "";
    const tournament: Tournament = {
      id: res.data.tournament._id,
      days: res.data.tournament.days,
      status: res.data.tournament.status,
      winner: res.data.tournament.winner,
      created: createdAt,
      endDate: expiresIn,
      started: started,
    };
    return tournament;
  } catch (err: any) {
    throw err.message;
  }
};
export const getAllTournamentsApi = async () => {
  try {
    const res = await axios.get(`${baseUrl}/tournament`);
    if (res.data.error) {
      throw res.data.error;
    }
    const tournaments: Tournament[] = res.data.tournaments.map(
      (tournament: any) => {
        const createdAt = moment(tournament.createdAt).format(
          "DD-MM-YYYY hh:mm"
        );
        const expiresIn = tournament.expiresIn
          ? moment(tournament.expiresIn).format("DD-MM-YYYY hh:mm")
          : "";
        const started = tournament.startedAt
          ? moment(tournament.startedAt).format("DD-MM-YYYY hh:mm")
          : "";
        return {
          id: tournament._id,
          days: tournament.days,
          status: tournament.status,
          winner: tournament.winner,
          created: createdAt,
          endDate: expiresIn,
          started: started,
        };
      }
    );
    return tournaments;
  } catch (err) {
    throw err;
  }
};

export const getRunningTournamentApi = async () => {
  try {
    const res = await axios.get(`${baseUrl}/tournament/running`);
    if (res.data.error) {
      throw {
        message: res.data.error,
      };
    } else {
      return res.data.tournament;
    }
  } catch (err: any) {
    throw err.message;
  }
};

export const getNextScheduledTournamentApi = async () => {
  try {
    const res = await axios.get(`${baseUrl}/tournament/scheduled`);
    if (res.status === 200) {
      return res.data.tournament;
    } else if (res.status === 404) {
      throw {
        message: "no tournament is running",
        code: 404,
      };
    }
  } catch (err: any) {
    throw err;
  }
};

export const deleteTournamentApi = async (id: string) => {
  try {
    const res = await axios.delete(`${baseUrl}/tournament/${id}`);
    if (res.data.error) {
      throw res.data.error;
    } else {
      return res.data;
    }
  } catch (err) {
    throw err;
  }
};

export const joinTournamentApi = async (user: string, players: string[]) => {
  try {
    const res = await axios.post(`${baseUrl}/tournament/join`, {
      user: user,
      players: players,
    });
    if (res.data.error) {
      throw res.data.error;
    }
    return res.data.tournament;
  } catch (err) {
    throw err;
  }
};

export const getUsersApi = async (tournamenId: string) => {
  try {
    const res = await axios.get(`${baseUrl}/tournament/${tournamenId}/user`);
    if (res.data.error) {
      throw {
        message: res.data.error,
      };
    }
    return res.data.users;
  } catch (err: any) {
    throw err.message;
  }
};

export const getTournamentByIdApi = async (tournamenId: string) => {
  try {
    const res = await axios.get(`${baseUrl}/tournament/${tournamenId}`);
    if (res.data.error) {
      throw {
        message: res.data.error,
      };
    }
    return res.data.tournament;
  } catch (err: any) {
    throw err.message;
  }
};

export const getResultByIdApi = async (tournamenId: string) => {
  try {
    const res = await axios.get(`${baseUrl}/tournament/${tournamenId}/matchUser`);
    if (res.data.error) {
      throw {
        message: res.data.error,
      };
    }
    return res.data.data;
  } catch (err: any) {
    throw err.message;
  }
};

export const isTournamentFullApi = async () => {
  try {
    const res = await axios.get(`${baseUrl}/tournament/is_full`);
    if (res.data.error) {
      return {
        message: res.data.error,
      };
    } else {
      return res.data;
    }
  } catch (err: any) {
    return err.message;
  }
};
