import axios from "axios";
import { baseUrl } from "../utils/constants";

export const getConfig = async () => {
  try {
    const res = await axios.get(`${baseUrl}/config`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateConfig = async (threeDayTounamentTime: String, fiveDayTounamentTime: String) => {
  try {
    const res = await axios.post(`${baseUrl}/config/update`, {
      fiveDayTounamentTime: fiveDayTounamentTime,
      threeDayTounamentTime: threeDayTounamentTime
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};