import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUsersApi, getResultByIdApi } from "../api/tournamentApi";
import { gradient } from "../utils/styles";
import FlexBox from "./containers/FlexBox";

interface Row {
  id: string;
}

const UserTable = ({ tournamentId }: { tournamentId: string }) => {
  const [rows, setRows] = useState<Row[]>([]);
  const navigate = useNavigate();
  const loadUsers = async () => {
    try {
      const res = await getResultByIdApi(tournamentId);
      console.log(res);
      const rows: Row[] = res.map((row: any) => {
        return {
          id: row.add1,          
          add1: row.add1,
          add2: row.add2,
          player1: row.player1,
          player2: row.player2,
          winner: row.winner,
        };
      });
      setRows(rows);
    } catch (err) {
      console.log(err);
    }
  };

  // columns for table
  const columns: GridColDef[] = [
    {
      field: "add1",
      headerName: "Address1",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 420,
    },
    {
      field: "player1",
      headerName: "Players1",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            sx={{ color: "white", borderColor: "primary.light" }}
            variant="outlined"
            onClick={() => {
              navigate(`/admin/players/${cellValues.row.add1}/${tournamentId}`);
            }}
          >
            Get
          </Button>
        );
      },
      width: 120,
    },
    {
      field: "add2",
      headerName: "Address2",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 420,
    },
    {
      field: "player2",
      headerName: "Players2",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            sx={{ color: "white", borderColor: "primary.light" }}
            variant="outlined"
            onClick={() => {
              navigate(`/admin/players/${cellValues.row.add2}/${tournamentId}`);
            }}
          >
            Get
          </Button>
        );
      },
      width: 120,
    },
    {
      field: "winner",
      headerName: "Winner",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 420,
    },
  ];

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        my: 1,
        "& .MuiTablePagination-displayedRows": {
          color: "white",
        },
        "& .MuiTablePagination-actions": {
          "& button": { "& svg": { color: "white" } },
        },
      }}
    >
      <FlexBox dir="column">
        <Box sx={{ width: "100%", my: 5 }}>
          <DataGrid
            sx={{
              borderImage: gradient,
              borderImageWidth: 1,
              borderImageSlice: 1,
              px: 2,
              color: "white",
              "& .MuiDataGrid-columnHeaderTitle": { fontSize: 16 },
            }}
            rows={rows}
            columns={columns}
            pageSize={10}
            autoHeight
            disableSelectionOnClick
          />
        </Box>
      </FlexBox>
    </Box>
  );
};

export default UserTable;
