import { Box, SxProps } from "@mui/system";
import React, { ReactNode } from "react";

const Background = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps;
}) => {
  return (
    <Box
      sx={[
        {
          py: 5,
          px: { xs: 2, md: 0 },
          width: "100vw",
          backgroundImage:
            "linear-gradient(to bottom right, rgba(0,0,0,.6), rgba(0,0,0.6)), url(/images/bg1.jpg)",
          minHeight: "100vh",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
};

export default Background;
