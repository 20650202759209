import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { isAdmin } from "../api/adminApi";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import Section from "../components/containers/Section";
import FlexBox from "../components/containers/FlexBox";
import DisconnectWalletButton from "../components/buttons/DisconnectWalletButton";
import Logo from "../components/Logo";
import ValueTable from "../components/ValueTable";
import { Button, TextField } from "@mui/material";
import { createValueApi } from "../api/valueApi";
import { useDispatch } from "react-redux";
import { addValue } from "../redux/valueSlice";
import LoadingComponent from "../components/LoadingBackdrop";
import MaterialSnackbar from "../components/snackbars/ErrorSnackbar";
import Header from "../components/Header";
import Background from "../components/containers/Background";

const ValuesPage = () => {
  const { publicKey } = useWallet();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [name, setName] = useState<string | undefined>();
  const [point, setPoint] = useState<string | undefined>();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<any>();

  const createValue = async () => {
    try {
      if (params.id) {
        setLoading(true);
        const res = await createValueApi(params.id, name, point);
        setLoading(false);
        dispatch(addValue(res));
      } else {
        console.log("no trait id");
      }
    } catch (err) {
      setLoading(false);
      setError(true);
      setMessage(err);
      setTimeout(() => {
        setError(false);
      }, 4000);
    }
  };

  useEffect(() => {
    if (!publicKey) {
      navigate("/");
    } else {
      isAdmin(publicKey.toString()).then((admin) => {
        if (!admin) {
          navigate("/");
        }
      });
    }
  }, [publicKey]);
  return (
    <Background>
      <LoadingComponent open={loading} />
      <MaterialSnackbar open={error} setOpen={setError} message={message} />
      <Section>
        <FlexBox dir="column">
          <Header />
          <FlexBox sx={{ mt: 3 }}>
            <TextField
              label="name"
              sx={{
                mr: 4,
                "& input": { color: "#D6DBDF" },
                "& .MuiFormControl-root": {
                  "&:hover": { borderColor: "primary.light" },
                },
                "& .MuiInputLabel-root": { color: "#D6DBDF" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.light",
                },
              }}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <TextField
              label="point"
              sx={{
                mr: 4,
                "& input": { color: "#D6DBDF" },
                "& .MuiFormControl-root": {
                  "&:hover": { borderColor: "primary.light" },
                },
                "& .MuiInputLabel-root": { color: "#D6DBDF" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.light",
                },
              }}
              onChange={(e) => {
                setPoint(e.target.value);
              }}
            />
            <Button
              sx={{ py: 3, px: 8 }}
              variant="contained"
              onClick={createValue}
            >
              Add
            </Button>
          </FlexBox>
          {params.id && <ValueTable id={params.id} />}
        </FlexBox>
      </Section>
    </Background>
  );
};

export default ValuesPage;
