import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Value from "../entities/Value";

const initialState: Value[] = [];

export const valueSlice = createSlice({
  name: "Value",
  initialState,
  reducers: {
    setValues: (state, action: PayloadAction<Value[]>) => {
      return action.payload;
    },
    addValue: (state, action: PayloadAction<Value>) => {
      return [...state, action.payload];
    },
    updateValueState: (state, action: PayloadAction<Value>) => {
      const index = state.findIndex((value) => value.id === action.payload.id);
      state[index] = action.payload;
    },
    removeValue: (state, action: PayloadAction<string>) => {
      const updatedState = state.filter((item) => item.id !== action.payload);
      return [...updatedState];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setValues, addValue, removeValue, updateValueState } =
  valueSlice.actions;

export default valueSlice.reducer;
