import { Box } from "@mui/system";
import { WalletDisconnectButton } from "@solana/wallet-adapter-react-ui";

const DisconnectWalletButton = () => {
  return (
    <Box
      sx={{
        "& button": {
          backgroundColor: "primary.main",
          minWidth: "15rem",
          display: "flex",
          justifyContent: "center",
        },
        "& .wallet-adapter-button:hover": {
          backgroundColor: "primary.dark",
        },
      }}
    >
      <WalletDisconnectButton />
    </Box>
  );
};

export default DisconnectWalletButton;
