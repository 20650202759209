import axios from "axios";
import { baseUrl } from "../utils/constants";

export const getStakedPlayersApi = async (user: string) => {
  try {
    const res = await axios.get(`${baseUrl}/player/${user}`);
    if (res.data.error) {
      throw res.data.error;
    } else {
      return res.data.stakedPlayers;
    }
  } catch (err) {
    throw err;
  }
};

export const getImagesByUriApi = async (uris: string[]) => {
  try {
    const res = await axios.post(`${baseUrl}/player/image`, {
      uris: uris,
    });
    if (res.data.error) {
      throw res.data.error;
    } else {
      return res.data.images;
    }
  } catch (err) {
    throw err;
  }
};

export const getPointsByMintApi = async (mint: string) => {
  try {
    const res = await axios.get(`${baseUrl}/player/points/${mint}`);
    if (res.data.error) {
      throw res.data.error;
    } else {
      return res.data;
    }
  } catch (err) {
    throw err;
  }
};
export const getStakedPlayersByUserApi = async (
  userAddress: string,
  tournamentId: string
) => {
  try {
    const res = await axios.post(`${baseUrl}/player/staked_players_by_user`, {
      userAddress: userAddress,
      tournamentId: tournamentId,
    });
    if (res.data.error) {
      throw res.data.error;
    } else {
      return res.data.players;
    }
  } catch (err) {
    throw err;
  }
};

export const getPlayersOnTournamentApi = async () => {
  try {
    const res = await axios.get(`${baseUrl}/player/players_on_tournament`);
    if (res.data.error) {
      throw {
        message: res.data.error,
      };
    } else {
      return res.data.players;
    }
  } catch (err: any) {
    throw err.message;
  }
};


export const getPlayersOnMatchApi = async (user: string) => {
  try {
    const res = await axios.get(`${baseUrl}/player/players_on_match/${user}`);
    if (res.data.error) {
      throw {
        message: res.data.error,
      };
    } else {
      return res.data.players;
    }
  } catch (err: any) {
    throw err.message;
  }
};