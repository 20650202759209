import { configureStore } from "@reduxjs/toolkit";
import tournamentReducer from "./tournamentSlice";
import nftReducer from "./nftSlice";
import traitReducer from "./traitSlice";
import valueReducer from "./valueSlice";

export const store = configureStore({
  reducer: {
    tournaments: tournamentReducer,
    nfts: nftReducer,
    traits: traitReducer,
    values: valueReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
