import axios from "axios";
import { baseUrl } from "../utils/constants";

export const getLastResultApi = async (user: String) => {
  try {
    const res = await axios.get(`${baseUrl}/result/${user}`);
    if (res.data.error) {
      return {
        message: res.data.error,
      };
    } else {
      return res.data.result;
    }
  } catch (err: any) {
    throw err.message;
  }
};
