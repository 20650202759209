import DisconnectWalletButton from "./buttons/DisconnectWalletButton";
import FlexBox from "./containers/FlexBox";
import Logo from "./Logo";

const Header = () => {
  return (
    <FlexBox
      jc="space-between"
      sx={{
        width: "100%",
        mb: 4,
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Logo />
      <DisconnectWalletButton />
    </FlexBox>
  );
};

export default Header;
