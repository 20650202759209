import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { gradient } from "../utils/styles";
import FlexBox from "./containers/FlexBox";

interface Row {
  id: string;
  address: string;
  wins: string;
}

const HallOfFameTable = ({ data }: { data: any }) => {
  const [rows, setRows] = useState<Row[]>([]);

  // columns for table
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      align: "center",
      hide: true,
      sortable: false,
      width: 420,
    },

    {
      field: "address",
      headerName: "Address",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 420,
    },
    {
      field: "wins",
      headerName: "Wins",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 420,
    },
  ];

  useEffect(() => {
    console.log("data", data);
    const rows: Row[] = data.map((d: any) => {
      return {
        id: d._id,
        address: d.address,
        wins: d.wins,
      };
    });
    setRows(rows);
  }, [data]);

  return (
    <Box
      sx={{
        width: "100%",
        my: 3,
        "& p": { color: "white" },
        "& .MuiTablePagination-actions": {
          "& button": { color: "white" },
        },
      }}
    >
      <FlexBox dir="column">
        <Box
          sx={{
            width: "100%",
            my: 2,
          }}
        >
          <DataGrid
            sx={{
              px: 2,
              borderImage: gradient,
              borderImageWidth: 1,
              borderImageSlice: 1,
              color: "white",
              "& .MuiDataGrid-columnHeaderTitle": { fontSize: 18 },
            }}
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            rowHeight={50}
          />
        </Box>
      </FlexBox>
    </Box>
  );
};

export default HallOfFameTable;
