import { Container, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  sx?: SxProps<Theme>;
  children: ReactNode;
}

const Section = ({ children, sx = {} }: Props) => {
  return (
    <Container maxWidth="lg" sx={sx}>
      {children}
    </Container>
  );
};

export default Section;
