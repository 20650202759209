import axios from "axios";
import { baseUrl } from "../utils/constants";

export const isAdmin = async (address: string) => {
  try {
    const res = await axios.post(`${baseUrl}/admin`, {
      id: address,
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
