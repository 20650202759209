import { useState, useEffect } from "react";
import { getRunningTournamentApi } from "../api/tournamentApi";

const useRunningTournament = () => {
  const [runningTournament, setRunningTournament] = useState<any>();
  const [loadingRunningTournament, setLoadingData] = useState<boolean>(false);

  const getRunningTournament = async () => {
    try {
      setLoadingData(true);
      const res = await getRunningTournamentApi();
      setLoadingData(false);
      setRunningTournament(res);
    } catch (err: any) {
      setLoadingData(false);
      if (err == "no tournament is running") {
        setRunningTournament(null);
      }
    }
  };

  useEffect(() => {
    try {
      getRunningTournament();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return [runningTournament, loadingRunningTournament];
};

export default useRunningTournament;
