import { Button, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import FlexBox from "./containers/FlexBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { deleteValueApi, getValuesByTraitApi } from "../api/valueApi";
import { removeValue, setValues } from "../redux/valueSlice";
import Value from "../entities/Value";
import EditValueBackdrop from "./backdrops/EditValueBackdrop";
import { gradient } from "../utils/styles";

const ValueTable = ({ id }: { id: string }) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [valueId, setValueId] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const values: Value[] = useSelector((state: RootState) => state.values);

  const loadValues = async () => {
    try {
      const res = await getValuesByTraitApi(id);
      const values: Value[] = res.map((value: any) => {
        return { id: value._id, name: value.name, point: value.point };
      });
      dispatch(setValues(values));
    } catch (err) {
      console.log(err);
    }
  };

  const deleteValue = async (id: string) => {
    try {
      const res = await deleteValueApi(id);
      dispatch(removeValue(res._id));
    } catch (err) {
      console.log(err);
    }
  };

  // columns for table
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      hide: true,
      sortable: false,
      width: 420,
    },

    {
      field: "name",
      headerAlign: "center",
      align: "center",
      headerName: "Value",
      sortable: false,
      width: 250,
    },
    {
      field: "point",
      headerAlign: "center",
      align: "center",
      headerName: "Point",
      sortable: false,
      width: 150,
    },

    {
      field: "edit",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <IconButton
            onClick={() => {
              setValueId(cellValues.id.toString());
              setEditing(true);
            }}
          >
            <EditIcon sx={{ color: "white" }} />
          </IconButton>
        );
      },
      width: 150,
    },
    {
      field: "delete",
      headerAlign: "center",
      align: "center",
      headerName: "Delete",
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <IconButton
            aria-label="delete"
            color="error"
            onClick={() => {
              deleteValue(cellValues.id.toString());
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
      width: 150,
    },
  ];

  useEffect(() => {
    loadValues();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        my: 1,
        "& p": { color: "white" },
        "& .MuiTablePagination-actions": {
          "& button": { color: "white" },
        },
      }}
    >
      <EditValueBackdrop open={editing} setOpen={setEditing} id={valueId} />
      <FlexBox dir="column">
        <Box sx={{ width: "100%", my: 5 }}>
          <DataGrid
            sx={{
              px: 2,
              borderImage: gradient,
              borderImageWidth: 1,
              borderImageSlice: 1,
              color: "white",
              "& .MuiDataGrid-columnHeaderTitle": { fontSize: 16 },
            }}
            rows={values}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </Box>
      </FlexBox>
    </Box>
  );
};

export default ValueTable;
