import { SxProps, Typography } from "@mui/material";
import { gradient } from "../../utils/styles";

const style = {
  fontSize: { xs: 18, lg: 24 },
  fontWeight: "500",
  background: gradient,
  backgroundClip: "text",
  color: "transparent",
  fontFamily: "inherit",
  mb: { xs: 2, lg: 1 },
};

const Heading2 = ({ text, sx }: { text: string; sx?: SxProps }) => {
  return (
    <Typography sx={{ ...style, ...sx }} variant="h2" className="text-gradient">
      {text}
    </Typography>
  );
};

export default Heading2;
