import { useEffect, useState } from "react";
import { isAdmin } from "../api/adminApi";
import { useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import Section from "../components/containers/Section";
import FlexBox from "../components/containers/FlexBox";
import { Box, Button, TextField } from "@mui/material";
import LoadingComponent from "../components/LoadingBackdrop";
import MaterialSnackbar from "../components/snackbars/ErrorSnackbar";
import Header from "../components/Header";
import Background from "../components/containers/Background";

const TraitDashboardPage = () => {
  const { publicKey } = useWallet();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [message, setMessage] = useState();
  const [threeDays, setThreeDays] = useState("");
  const [fiveDays, setFiveDays] = useState("");
  const [threeDaysCurrent, setThreeDaysCurrent] = useState("");
  const [fiveDaysCurrent, setFiveDaysCurrent] = useState("");
  const { getConfig, updateConfig } = require("../api/configApi");

  useEffect(() => {
    if (!publicKey) {
      navigate("/");
    } else {
      isAdmin(publicKey.toString()).then((admin) => {
        if (!admin) {
          navigate("/");
        } else {
          getConfig().then(res => {
            setThreeDaysCurrent(res.threeDayTounamentTime)
            setFiveDaysCurrent(res.fiveDayTounamentTime)
            setThreeDays(res.threeDayTounamentTime)
            setFiveDaysCurrent(res.fiveDayTounamentTime)
          });

        }
      });
    }
  }, [publicKey]);
  return (
    <Background>
      <LoadingComponent open={loading} />
      <MaterialSnackbar open={error} setOpen={setError} message={message} />
      <Section>
        <FlexBox dir="column">
          <Header />
          <FlexBox sx={{ my: 2 }}>
            <TextField
              label="Enter 3 day tounament (hours)"
              placeholder={threeDaysCurrent}
              sx={{
                mr: 2,
                "& input": { color: "#D6DBDF" },
                "& .MuiFormControl-root": {
                  "&:hover": { borderColor: "primary.light" },
                },
                "& .MuiInputLabel-root": { color: "#D6DBDF" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.light",
                },
              }}
              onChange={(e) => {
                setThreeDays(e.target.value);
              }}
            />
            <Button variant="contained" sx={{ ml: 1 }} onClick={async () => {
              setLoading(true);
              await updateConfig(threeDays != "" ? threeDays : threeDaysCurrent, fiveDays != "" ? fiveDays : fiveDaysCurrent);
              setLoading(false);
              navigate("/admin/config")
            }}>
              Update
            </Button>
          </FlexBox>

          <FlexBox sx={{ my: 2 }}>
            <TextField
              label="Enter 5 day tounament (hours)"
              placeholder={fiveDaysCurrent}
              sx={{
                mr: 2,
                "& input": { color: "#D6DBDF" },
                "& .MuiFormControl-root": {
                  "&:hover": { borderColor: "primary.light" },
                },
                "& .MuiInputLabel-root": { color: "#D6DBDF" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.light",
                },
              }}
              onChange={(e) => {
                setFiveDays(e.target.value);
              }}
            />
            <Button variant="contained" sx={{ ml: 1 }} onClick={async () => {
              setLoading(true);
              await updateConfig(threeDays != "" ? threeDays : threeDaysCurrent, fiveDays != "" ? fiveDays : fiveDaysCurrent);
              setLoading(false);
              navigate("/admin/config")
            }}>
              Update
            </Button>
          </FlexBox>
        </FlexBox>
      </Section>
    </Background>
  );
};

export default TraitDashboardPage;
