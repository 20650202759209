import { Box } from "@mui/material";
import { FC, useEffect } from "react";
import ConnectWalletButton from "../components/buttons/ConnectWalletButton";
import FlexBox from "../components/containers/FlexBox";
import Section from "../components/containers/Section";
import Logo from "../components/Logo";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import { isAdmin } from "../api/adminApi";
import Background from "../components/containers/Background";

const HomePage: FC = () => {
  const { connection } = useConnection();
  const navigate = useNavigate();
  const { publicKey } = useWallet();

  const checkAdmin = async () => {
    try {
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (publicKey) {
      isAdmin(publicKey.toString()).then((admin) => {
        if (admin) {
          navigate("/admin");
        } else {
          navigate("/hall_of_fame");
        }
      });
    }
  }, [publicKey]);
  return (
    <Background sx={{ height: { lg: "100vh" }, overflow: "hidden" }}>
      <Section>
        <FlexBox dir="column">
          <FlexBox
            jc="space-between"
            sx={{
              width: "100%",
              mb: 2,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Logo />
            <ConnectWalletButton />
          </FlexBox>
          <Box
            sx={{
              "& img": { objectFit: "contain" },
              height: "60vh",
              width: "100%",
            }}
          >
            <img src="/images/season1.png" height="100%" width="100%" />
          </Box>
        </FlexBox>
      </Section>
    </Background>
  );
};

export default HomePage;
