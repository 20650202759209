import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import FlexBox from "../components/containers/FlexBox";
import Section from "../components/containers/Section";
import Logo from "../components/Logo";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { TOKEN_PROGRAM_ID, AccountLayout } from "@solana/spl-token";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DisconnectWalletButton from "../components/buttons/DisconnectWalletButton";
import { NFT } from "../entities/Nft";
import { useDispatch, useSelector } from "react-redux";
import {
  addToFree,
  addToStake,
  removeFromFree,
  removeFromStake,
  resetFreeNfts,
  resetStakedNfts,
  setFreeNfts,
  setStakeNfts,
  setTotalNfts,
} from "../redux/nftSlice";
import { RootState } from "../redux/store";
import { isTournamentFullApi, joinTournamentApi } from "../api/tournamentApi";
import LoadingComponent from "../components/LoadingBackdrop";
import MaterialSnackbar from "../components/snackbars/ErrorSnackbar";
import useRunningTournament from "../hooks/useRunningTournament";
import { isMintAllowedApi } from "../api/mintApi";
import Background from "../components/containers/Background";
import Heading2 from "../components/typography/Heading2";
import CircularBar from "../components/CircularBar";
import useScheduledTournament from "../hooks/useScheduledTournament";
import JoinTournamentMain from "../components/JoinTournamentMain";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { Source } from "@mui/icons-material";
import { getPointsByMintApi } from "../api/playerApi";

const JoinTournamentPage = () => {
  const { connection } = useConnection();
  const { publicKey } = useWallet();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const freeNfts = useSelector((state: RootState) => state.nfts.free);
  const stakeNfts = useSelector((state: RootState) => state.nfts.stake);
  const [loading, setLoading] = useState<boolean>(false);
  const [joiningTournament, setJoiningTournament] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string | unknown>();
  const [runningTournament, loadingRunningTournament] = useRunningTournament();
  const [scheduledTournament, loadingScheduledTournament] =
    useScheduledTournament();

  const getTotalNfts = async () => {
    try {
      if (publicKey) {
        setLoading(true);
        // get all the mints
        const tokenAccounts = await connection.getTokenAccountsByOwner(
          publicKey,
          {
            programId: TOKEN_PROGRAM_ID,
          }
        );

        const mints: string[] = [];

        await Promise.all(
          tokenAccounts.value.map(async (e) => {
            const accInfo = AccountLayout.decode(e.account.data);
            // check if the mint is transferred
            if (accInfo.amount.toString() != "0") {
              //mints.push(accInfo.mint.toString());

              //check if mint is allowed to be staked
              const allowed = await isMintAllowedApi(accInfo.mint.toString());
              if (allowed) {
                mints.push(accInfo.mint.toString());
              }
            }
          })
        );

        // get players metadata by mint
        const metadatas = await Promise.all(
          mints.map(async (mint) => {
            const metadata = await Metadata.findByMint(connection, mint);
            return metadata;
          })
        );

        // get images and points from the metadata and set NFT
        const Nfts: NFT[] = await Promise.all(
          metadatas.map(async (metadata) => {
            // const points = {
            //   points: 80.2589,
            // };

            const points = await getPointsByMintApi(metadata.data.mint);

            const res = await axios.get(metadata.data.data.uri);
            return {
              mint: metadata.data.mint,
              image: res.data.image,
              points: parseFloat(points.points.toFixed(1)),
            };
          })
        );

        setLoading(false);
        dispatch(setTotalNfts(Nfts));
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const joinTournament = async () => {
    try {
      setJoiningTournament(true);
      if (publicKey) {
        const players: string[] = stakeNfts.map((nft) => {
          return nft.mint;
        });
        const user = publicKey.toString();
        const res = await joinTournamentApi(user, players);
        setJoiningTournament(false);
        navigate("/players_on_tournament");
      }
    } catch (err) {
      setJoiningTournament(false);
      setError(true);
      setMessage(err);
      setTimeout(() => {
        setError(false);
      }, 4000);
    }
  };
  const onDragEnd = (result: DropResult) => {
    console.log(result);

    let free = [...freeNfts];
    let stake = [...stakeNfts];

    if (
      result.source.droppableId === "free" &&
      result.destination?.droppableId === "stake"
    ) {
      // add the moved item to add variable
      let add = free[result.source.index];
      // remove it from original source
      free.splice(result.source.index, 1);
      console.log("add", add);
      // add to destination
      stake.splice(result.destination.index, 0, add);
      dispatch(removeFromFree(add));
      dispatch(addToStake(add));
    } else if (
      result.source.droppableId === "stake" &&
      result.destination?.droppableId === "free"
    ) {
      // save moved variable
      let add = stake[result.source.index];
      // remove it from source
      stake.splice(result.source.index, 1);
      // add to destination
      free.splice(result.destination.index, 0, add);
      dispatch(removeFromStake(add));
      dispatch(addToFree(add));
    } else {
      return;
    }
  };

  useEffect(() => {
    if (!publicKey) {
      navigate("/");
    } else {
      getTotalNfts();
    }
  }, [publicKey]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Background>
        <LoadingComponent open={joiningTournament} />
        <MaterialSnackbar open={error} setOpen={setError} message={message} />
        <Section>
          <FlexBox dir="column">
            <FlexBox
              jc="space-between"
              sx={{
                width: "100%",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Logo />
              <DisconnectWalletButton />
            </FlexBox>

            {!loading}

            {/* if tournament is running */}
            {!loadingRunningTournament && runningTournament && (
              <JoinTournamentMain
                loading={loading}
                joinTournament={joinTournament}
              />
            )}

            {/* if tournament is not running but scheduled */}
            {!loadingRunningTournament &&
              !runningTournament &&
              !loadingScheduledTournament &&
              scheduledTournament && (
                <JoinTournamentMain
                  loading={loading}
                  joinTournament={joinTournament}
                />
              )}

            {/* if no tournament is running and scheduled */}
            {!loadingRunningTournament &&
              !runningTournament &&
              !loadingScheduledTournament &&
              !scheduledTournament && (
                <Box>
                  <Heading2 text="No Tournament Is Scheduled" />
                </Box>
              )}
            {loadingRunningTournament ||
              (loadingScheduledTournament && (
                <FlexBox sx={{ height: "65vh" }}>
                  <CircularBar />
                </FlexBox>
              ))}
          </FlexBox>
        </Section>
      </Background>
    </DragDropContext>
  );
};

export default JoinTournamentPage;
