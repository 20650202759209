import axios from "axios";
import { baseUrl } from "../utils/constants";

export const isMintAllowedApi = async (mint: string) => {
  try {
    const res = await axios.get(`${baseUrl}/nft/${mint}`);
    return res.data;
  } catch (err: any) {
    throw err;
  }
};
