import { Box, Typography } from "@mui/material";
import FlexBox from "./containers/FlexBox";
import Body1 from "./typography/Body1";
import Body2 from "./typography/Body2";
import Heading1 from "./typography/Heading1";
import PrimaryButton from "./buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import Timer from "./Timer";
import useTimer from "../hooks/useTimer";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { NFT } from "../entities/Nft";
import useRunningTournament from "../hooks/useRunningTournament";
import useScheduledTournament from "../hooks/useScheduledTournament";
import Heading2 from "./typography/Heading2";
import Nft from "./Nft";
import { Droppable } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import { getStakedPlayersApi } from "../api/playerApi";
import { useDispatch } from "react-redux";
import { resetFreeNfts, resetStakedNfts, setFreeNfts } from "../redux/nftSlice";
import { useWallet } from "@solana/wallet-adapter-react";
import useTotalStakedPoints from "../hooks/useTotalPoints";
import { isTournamentFullApi } from "../api/tournamentApi";

interface Props {
  loading: boolean;
  joinTournament: Function;
}

const JoinTournamentMain = ({ loading, joinTournament }: Props) => {
  const { publicKey } = useWallet();
  const navigate = useNavigate();
  const [expiry] = useTimer();
  const dispatch = useDispatch();
  const totalNfts = useSelector((state: RootState) => state.nfts.total);
  const freeNfts = useSelector((state: RootState) => state.nfts.free);
  const stakeNfts = useSelector((state: RootState) => state.nfts.stake);
  const totalPoints = useSelector((state: RootState) => state.nfts.totalPoints);
  const [runningTournament, loadingRunningTournament] = useRunningTournament();
  const [scheduledTournament, loadingScheduledTournament] =
    useScheduledTournament();
  const [totalStakedPoints] = useTotalStakedPoints();
  const [isTournamentFull, setIsTournamentFull] = useState<boolean>(false);
  const [loadingIsFull, setloadingIsFull] = useState<boolean>(false);

  const getFreeNfts = async (pubKey: string) => {
    try {
      if (totalNfts != undefined) {
        const res = await getStakedPlayersApi(pubKey);
        // mints of staked players
        const mints = res.map((item: any) => item.mint);

        // await getMetadata();
        // set free nfts
        let freeNfts: NFT[] = [];
        totalNfts.map((nft: any) => {
          if (!mints.includes(nft.mint)) {
            freeNfts.push(nft);
          }
        });
        dispatch(setFreeNfts(freeNfts));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkIsTournamentFull = async () => {
    try {
      setloadingIsFull(true);
      const res = await isTournamentFullApi();
      setloadingIsFull(false);
      setIsTournamentFull(res);
    } catch (err) {
      setloadingIsFull(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (publicKey) {
      getFreeNfts(publicKey.toString());
      checkIsTournamentFull();
    }
    return () => {
      dispatch(resetStakedNfts());
      dispatch(resetFreeNfts());
    };
  }, [totalNfts]);

  return (
    <FlexBox dir="column" sx={{ width: "100%" }}>
      <FlexBox
        sx={{
          mt: { xs: 8, md: 0 },
          mb: 5,
          width: "100%",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box sx={{ mr: 12 }}>
          <img src="/images/heropack.png" height={180} />
        </Box>

        {/* show only if tournament is running */}
        {!loadingRunningTournament && runningTournament && (
          <FlexBox dir="column" sx={{ mt: { xs: 4, md: 0 } }}>
            <Body1 sx={{ fontSize: 21 }} text="Match ends in" />
            {expiry && <Timer expiry={expiry} />}
          </FlexBox>
        )}
        {/* if tournament is scheduled */}
        {!loadingScheduledTournament &&
          scheduledTournament &&
          !runningTournament &&
          !loadingRunningTournament && (
            <Heading2 text="Waiting for players to join" />
          )}
      </FlexBox>
      {isTournamentFull && runningTournament && !loadingIsFull ? (
        <Heading2 text="Tournament is full" sx={{ mt: 2 }} />
      ) : (
        <FlexBox dir="column" sx={{ width: "100%" }}>
          <Heading1 text="Prepare your team" sx={{ mb: 3 }} />
          <Body1
            text={`Points : ${totalStakedPoints} Points`}
            sx={{ textAlign: "center", mt: 1 }}
          />

          <FlexBox
            sx={{
              mt: 4,
              width: "100%",

              flexDirection: {
                xs: "column",
                md: "row",
              },
            }}
            jc="space-around"
            ai="center"
          >
            <Box sx={{ width: "100%" }}>
              <Body2 text="Free players" sx={{ textAlign: "center" }} />

              <Droppable droppableId="free">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{
                      p: 3,
                      minHeight: "25rem",
                      background: "transparent",
                      border: 1,
                      borderColor: "primary.main",
                      m: 2,
                      "& svg": { color: "primary.light", width: 18 },
                    }}
                  >
                    {loading && (
                      <Typography sx={{ color: "white", textAlign: "center" }}>
                        Loading...
                      </Typography>
                    )}

                    {!loading && freeNfts.length == 0 && (
                      <Body2
                        text="No free players"
                        sx={{ textAlign: "center" }}
                      />
                    )}

                    <FlexBox sx={{ flexWrap: "wrap" }}>
                      {!loading &&
                        freeNfts.length > 0 &&
                        freeNfts.map((freeNft, index) => {
                          return (
                            <Nft
                              key={freeNft.mint}
                              mint={freeNft.mint}
                              points={freeNft.points}
                              imageUrl={freeNft.image}
                              index={index}
                            />
                          );
                        })}
                    </FlexBox>

                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Box>

            <Box sx={{ width: "100%" }}>
              <Body2
                text="Players for tournament"
                sx={{ textAlign: "center" }}
              />

              {/* dropable */}
              <Droppable droppableId="stake">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{
                      p: 3,
                      minHeight: "25rem",
                      background: "transparent",
                      border: 1,
                      borderColor: "primary.main",
                      m: 2,
                      "& svg": { color: "primary.light", width: 18 },
                    }}
                  >
                    <FlexBox sx={{ flexWrap: "wrap" }}>
                      {!loading &&
                        stakeNfts.length > 0 &&
                        stakeNfts.map((stakeNft: any, index) => (
                          <Nft
                            key={stakeNft.mint}
                            mint={stakeNft.mint}
                            points={stakeNft.points}
                            imageUrl={stakeNft.image}
                            index={index}
                          />
                        ))}
                    </FlexBox>

                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Box>
          </FlexBox>
          <FlexBox
            sx={{
              width: "100%",
              mt: 3,
              flexDirection: { xs: "column", md: "row" },
            }}
            jc="space-around"
          >
            <PrimaryButton
              sx={{ mt: 3, height: "2.7rem" }}
              text="Play"
              size="medium"
              onClick={() => {
                joinTournament();
              }}
            />
            <PrimaryButton
              sx={{ mt: 3, height: "2.7rem" }}
              text="Players On Tournament"
              size="medium"
              onClick={() => {
                navigate("/players_on_tournament");
              }}
            />
          </FlexBox>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default JoinTournamentMain;
