import axios from "axios";
import { baseUrl } from "../utils/constants";

export const getTraitsApi = async () => {
  try {
    const res = await axios.get(`${baseUrl}/trait`);
    if (res.data.error) {
      throw res.data.error;
    } else {
      return res.data;
    }
  } catch (err) {
    throw err;
  }
};

export const createTraitApi = async (name: string) => {
  try {
    const res = await axios.post(`${baseUrl}/trait`, { name: name });
    if (res.data.error) {
      throw res.data.error;
    } else {
      return {
        name: res.data.trait.name,
        id: res.data.trait._id,
      };
    }
  } catch (err) {
    throw err;
  }
};
export const deleteTraitApi = async (id: string) => {
  try {
    const res = await axios.delete(`${baseUrl}/trait/${id}`);
    if (res.data.error) {
      throw res.data.error;
    } else {
      return res.data.trait;
    }
  } catch (err) {
    throw err;
  }
};

export const updateTraitApi = async (name: string, id: string) => {
  try {
    const res = await axios.put(`${baseUrl}/trait/${id}`, { name: name });
    if (res.data.error) {
      throw res.data.error;
    } else {
      return {
        id: res.data.trait._id,
        name: res.data.trait.name,
      };
    }
  } catch (err) {
    throw err;
  }
};
