import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Trait from "../entities/Trait";

const initialState: Trait[] = [];

export const traitSlice = createSlice({
  name: "trait",
  initialState,
  reducers: {
    setTraits: (state, action: PayloadAction<Trait[]>) => {
      return action.payload;
    },
    addTrait: (state, action: PayloadAction<Trait>) => {
      return [...state, action.payload];
    },
    updateTraitState: (state, action: PayloadAction<Trait>) => {
      const index = state.findIndex((trait) => trait.id === action.payload.id);
      state[index] = action.payload;
    },
    removeTrait: (state, action: PayloadAction<string>) => {
      const updatedState = state.filter((item) => item.id !== action.payload);
      return [...updatedState];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTraits, addTrait, removeTrait, updateTraitState } =
  traitSlice.actions;

export default traitSlice.reducer;
