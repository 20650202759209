import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Tournament from "../entities/Tournament";

const initialState: Tournament[] = [];

export const tournamentSlice = createSlice({
  name: "tournament",
  initialState,
  reducers: {
    setTournaments: (state, action: PayloadAction<Tournament[]>) => {
      return action.payload;
    },
    addTournament: (state, action: PayloadAction<Tournament>) => {
      return [...state, action.payload];
    },
    removeTournament: (state, action: PayloadAction<string>) => {
      const updatedState = state.filter((item) => item.id !== action.payload);
      return [...updatedState];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTournaments, addTournament, removeTournament } =
  tournamentSlice.actions;

export default tournamentSlice.reducer;
