import { Box, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import DisconnectWalletButton from "../components/buttons/DisconnectWalletButton";
import FlexBox from "../components/containers/FlexBox";
import NftContainer from "../components/containers/NftContainer";
import Section from "../components/containers/Section";
import Logo from "../components/Logo";
import Body1 from "../components/typography/Body1";
import Heading2 from "../components/typography/Heading2";
import Timer from "../components/Timer";
import { useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import useTimer from "../hooks/useTimer";
import {
  getPlayersOnTournamentApi,
  getStakedPlayersApi,
  getPlayersOnMatchApi,
} from "../api/playerApi";
import axios from "axios";
import useRunningTournament from "../hooks/useRunningTournament";
import Background from "../components/containers/Background";
import Body2 from "../components/typography/Body2";
import CircularBar from "../components/CircularBar";

interface Player {
  image: string;
  number: number;
}

const PlayersOnTournamentPage: FC = () => {
  const navigate = useNavigate();
  const { publicKey } = useWallet();
  const [expiry] = useTimer();
  const [loadingPlayers, setLoadingPlayers] = useState<boolean>(false);
  const [images, setImages] = useState<string[] | undefined>(undefined);
  const [runningTournament, loadingRunningTournament] = useRunningTournament();
  const [players, setPlayers] = useState<Player[] | undefined>();

  const loadNfts = async (user: string) => {
    try {
      setLoadingPlayers(true);
      if (publicKey) {
        const user = publicKey.toString();
        const res = await getPlayersOnMatchApi(user);

        const players: Player[] = await Promise.all(
          res.map(async (player: any) => {
            const response = await axios.get(player.uri);
            const image = response.data.image;
            return {
              image,
              number: player.number,
            };
          })
        );
        setPlayers(players);

        setLoadingPlayers(false);
      }
      // setImages(imgs);
    } catch (err) {
      setLoadingPlayers(false);
      console.log(err);
    }
  };
  useEffect(() => {
    if (!publicKey) {
      navigate("/");
    } else {
      loadNfts(publicKey.toString());
    }
  }, [publicKey]);
  return (
    <Background sx={{}}>
      <Section>
        <FlexBox dir="column">
          <FlexBox
            jc="space-between"
            sx={{
              width: "100%",
              mb: 8,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Logo />
            <DisconnectWalletButton />
          </FlexBox>

          {runningTournament && !loadingRunningTournament && (
            <FlexBox dir="column" sx={{ width: "100%" }}>
              <FlexBox>
                <FlexBox dir="column" sx={{ mx: { lg: 4 } }}>
                  <Heading2 text="Players on the match" sx={{ fontSize: 28 }} />
                  <Body2
                    sx={{ mt: 2 }}
                    text="Your players will be back home from match in"
                  />
                  {expiry && <Timer expiry={expiry} />}
                </FlexBox>
              </FlexBox>

              <Box
                sx={{
                  backgroundImage: "url(/images/potbgs.jpg)",
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                  height: "500px",
                  mt: 5,
                  position: "relative",
                  width: "1280px",
                  "& div": { mx: 1 },
                }}
              >
                {players != undefined &&
                  players.map((player) => (
                    <Box
                      key={player.image}
                      sx={{
                        position: "absolute",
                        top:
                          player.number == 1
                            ? "197px"
                            : player.number == 2
                            ? "268px"
                            : player.number == 3
                            ? "232px"
                            : player.number == 4
                            ? "170px"
                            : player.number == 5
                            ? "294px"
                            : player.number == 6
                            ? "200px"
                            : player.number == 7
                            ? "272px"
                            : player.number == 8
                            ? "235px"
                            : player.number == 9
                            ? "170px"
                            : player.number == 10
                            ? "293px"
                            : "0px",
                        left:
                          player.number == 1
                            ? "384px"
                            : player.number == 2
                            ? "384px"
                            : player.number == 3
                            ? "500px"
                            : player.number == 4
                            ? "555px"
                            : player.number == 5
                            ? "555px"
                            : player.number == 6
                            ? "824px"
                            : player.number == 7
                            ? "818px"
                            : player.number == 8
                            ? "718px"
                            : player.number == 9
                            ? "656px"
                            : player.number == 10
                            ? "655px"
                            : "0px",
                        "& img": { objectFit: "contain" },
                      }}
                    >
                      <img src={player.image} width="45px" />
                    </Box>
                  ))}
                {loadingPlayers && <CircularBar />}
                {!loadingPlayers && images?.length == 0 && (
                  <Body1 text="No players are staked" />
                )}
              </Box>
            </FlexBox>
          )}
          {!loadingRunningTournament && !runningTournament && (
            <Box>
              <Heading2 text="No tournament is running" />
            </Box>
          )}
          {loadingRunningTournament && (
            <FlexBox sx={{ height: "65vh" }}>
              <CircularBar />
            </FlexBox>
          )}
        </FlexBox>
      </Section>
    </Background>
  );
};

export default PlayersOnTournamentPage;
