import { useEffect, useState } from "react";
import { isAdmin } from "../api/adminApi";
import { useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import Section from "../components/containers/Section";
import FlexBox from "../components/containers/FlexBox";
import Logo from "../components/Logo";
import DisconnectWalletButton from "../components/buttons/DisconnectWalletButton";
import { Box, Button, TextField } from "@mui/material";
import { createTraitApi } from "../api/traitApi";
import TraitTable from "../components/TraitTable";
import { addTrait } from "../redux/traitSlice";
import { useDispatch } from "react-redux";
import LoadingComponent from "../components/LoadingBackdrop";
import MaterialSnackbar from "../components/snackbars/ErrorSnackbar";
import Header from "../components/Header";
import Background from "../components/containers/Background";

const TraitDashboardPage = () => {
  const { publicKey } = useWallet();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [message, setMessage] = useState();

  const createTrait = async () => {
    try {
      setLoading(true);
      const res = await createTraitApi(name);
      setLoading(false);
      dispatch(addTrait(res));
    } catch (err) {
      setLoading(false);
      setError(true);
      setMessage(err);
      setTimeout(() => {
        setError(false);
      }, 4000);
    }
  };

  useEffect(() => {
    if (!publicKey) {
      navigate("/");
    } else {
      isAdmin(publicKey.toString()).then((admin) => {
        if (!admin) {
          navigate("/");
        }
      });
    }
  }, [publicKey]);
  return (
    <Background>
      <LoadingComponent open={loading} />
      <MaterialSnackbar open={error} setOpen={setError} message={message} />
      <Section>
        <FlexBox dir="column">
          <Header />
          <FlexBox sx={{ my: 2 }}>
            <TextField
              label="name"
              sx={{
                mr: 2,
                "& input": { color: "#D6DBDF" },
                "& .MuiFormControl-root": {
                  "&:hover": { borderColor: "primary.light" },
                },
                "& .MuiInputLabel-root": { color: "#D6DBDF" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.light",
                },
              }}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <Button variant="contained" sx={{ ml: 1 }} onClick={createTrait}>
              Add
            </Button>
          </FlexBox>
          <TraitTable />
        </FlexBox>
      </Section>
    </Background>
  );
};

export default TraitDashboardPage;
