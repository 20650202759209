import { Button, IconButton, Paper, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FlexBox from "./containers/FlexBox";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateValueApi } from "../api/valueApi";
import { updateValueState } from "../redux/valueSlice";

const EditValue = ({ setOpen, id }: { setOpen: Function; id: string }) => {
  const [name, setName] = useState<string | undefined>();
  const [point, setPoint] = useState<string | undefined>();
  const dispatch = useDispatch();
  const updateValue = async () => {
    try {
      const res = await updateValueApi(id, name, point);
      dispatch(updateValueState(res));
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Paper
      elevation={5}
      sx={{
        px: 2,
        pt: 8,
        pb: 6,
        minWidth: "25rem",
        position: "relative",
        backgroundColor: "#1F1F1F",
      }}
    >
      <IconButton
        sx={{ position: "absolute", right: "2rem" }}
        color="primary"
        onClick={() => {
          setOpen(false);
        }}
      >
        <CloseIcon sx={{ color: "white" }} />
      </IconButton>
      <FlexBox dir="column">
        <TextField
          label="name"
          sx={{
            mt: 1,
            mb: 2,
            border: "none",
            "& input": { color: "#D6DBDF" },
            "& .MuiInputLabel-root": { color: "#D6DBDF" },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.main",
            },
          }}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="point"
          sx={{
            mt: 1,
            mb: 3,
            border: "none",
            "& input": { color: "#D6DBDF" },
            "& .MuiInputLabel-root": { color: "#D6DBDF" },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "primary.main",
            },
          }}
          onChange={(e) => setPoint(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{ my: 2, py: 3, px: 8 }}
          onClick={() => {
            updateValue();
          }}
        >
          Update
        </Button>
      </FlexBox>
    </Paper>
  );
};

export default EditValue;
