import { SxProps, Typography } from "@mui/material";

const style = {
  fontSize: { xs: 12, lg: 15 },
  color: "white",
  fontFamily: "inherit",
};

const Body2 = ({ text, sx }: { text: string; sx?: SxProps }) => {
  return (
    <Typography sx={{ ...style, ...sx }} variant="body2">
      {text}
    </Typography>
  );
};

export default Body2;
