import { SxProps, Typography } from "@mui/material";

const style = {
  fontSize: 36,
  fontWeight: "500",
  background:
    "linear-gradient(225deg, hsla(51, 100%, 50%, 1) 0%, hsla(0, 0%, 100%, 1) 100%)",
  backgroundClip: "text",
  color: "transparent",
  fontFamily: "inherit",
};

const Heading1 = ({ text, sx }: { text: string; sx?: SxProps }) => {
  return (
    <Typography sx={{ ...style, ...sx }} variant="h1">
      {text}
    </Typography>
  );
};

export default Heading1;
